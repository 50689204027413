import React, { useCallback, useEffect, useState } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { PageWrapper } from '../Login/components/PageWrapper';
import * as S from './styles';
import { StyledDefaultTheme, Typography } from '~/components/@hello-ui';
import { Listing } from '~/components/@hello-ui/Listing';
import { useApi } from '~/hooks/api';
import { AuthService } from '~/auth/auth-service';
import { useAuthStore } from '~/store/auth.store';
import { IProduct } from '~/@types/auth/product';
import { Loading } from '~/components/Loading';
import { PageWrapperWithScroll } from '~/screens/Login/components/PageWrapper/PageWrapperWithScroll';

export const Products = (): JSX.Element => {
  const user = useAuthStore((st) => st.user);
  const userProducts = useAuthStore((st) => st.userProducts);
  const [loading, setLoading] = useState(true);
  const { authStrategy } = useAuthStore();

  const api = useApi();
  const theme = useTheme();

  // Produtos Mercado Pago
  const productsMP = ['7569', '7570', '7571'];

  // Produtos PagBank
  const priorityProducts = ['7066', '7067', '7068', '7487'];

  const products =
    authStrategy === 'PasswordStrategy'
      ? userProducts?.filter((product) => !productsMP.includes(product.onixCode))
      : userProducts;

  useEffect(() => {
    if (!products || !products.length) {
      AuthService.logout().then(() => {
        console.warn('User has no products to select.');
      });
      return;
    }

    // Filtrar os produtos prioritários
    const prioritized = products
      .filter((product) => priorityProducts.includes(product.onixCode))
      .sort((a, b) => b.onixCode.localeCompare(a.onixCode)); // Ordenar por maior onixCode

    if (prioritized.length > 0) {
      // Seleciona o maior onixCode prioritário
      onSelectProduct(prioritized[0]).finally(() => setLoading(false));
      return;
    }

    if (products.length === 1) {
      // Seleciona automaticamente se houver apenas um produto
      onSelectProduct(products[0]).finally(() => setLoading(false));
    } else {
      // Caso contrário, exibe a tela de seleção
      setLoading(false);
    }
  }, [products, onSelectProduct]);

  const onSelectProduct = useCallback(async (product: IProduct) => {
    await AuthService.selectProduct(product.onixCode);
    await AuthService.triggerGatekeeperValidation();
    
    await api.accessByProduct(user?.cpf as string, product);
  }, [api, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapperWithScroll>
      <PageWrapper className="mobile:px-0">
        <View className="mb-80 mobile:mb-40 mobile:px-16">
          <Typography variant="title">Selecione o produto que deseja acessar</Typography>
        </View>
        <Listing
          useSeparator
          hideBackToTop
          separatorColor={theme.colors.black5}
          data={products ?? []}
          containerStyle={{ height: 'auto' }}
          renderItem={({ item }: { item: IProduct }) => {
            const { name } = item;

            return (
              <S.WrapperButton onPress={() => onSelectProduct(item)}>
                <Typography variant="bodyHighlight2">{name}</Typography>
                <Icon name="chevron-right" size={24} color={StyledDefaultTheme.colors.success} />
              </S.WrapperButton>
            );
          }}
        />
      </PageWrapper>
    </PageWrapperWithScroll>
  );
};
