import React, { useEffect, useState, createContext, useRef } from 'react';
import { CardType, CardStatementType } from './types';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { GenericFunction } from '~/@types/helpers';

export interface TemPayInstance {
  card: CardType['data'] | null;
  statement: CardStatementType[] | null;
  loadingStatement: boolean;
  loadingCard: boolean;
  loadCard: () => Promise<any>;
}

export interface TemPayProviderTypes {
  children: JSX.Element | JSX.Element[];
}

export const TemPayContext = createContext<TemPayInstance>({} as TemPayInstance);

function calculateTimeout(index: number) {
  return index + index * 2000;
}

/**
 * When calling this functions many times, it will wait more time for each try.
 * Also, when calling this function twice, while the last promise doesn't resolve yet,
 * It will use the value from the last promise
 *
 * Call success() to reset the timeout
 * @param callback
 */
function useProgressiveRetry(callback: (success: GenericFunction, ...args: any[]) => Promise<any>) {
  const retryingPromise = useRef<Promise<any> | null>(null);
  const currentTimeoutIndex = useRef<number>(0);

  const success = () => {
    currentTimeoutIndex.current = -1;
  };

  return async (...args: unknown[]) => {
    return new Promise((resolve, reject) => {
      if (retryingPromise.current) {
        retryingPromise.current.then((res) => {
          resolve(res);
        });
        retryingPromise.current.catch((err) => {
          reject(err);
        });
        return;
      }

      retryingPromise.current = new Promise((rs, rj) => {
        window.setTimeout(async () => {
          try {
            const response: unknown = await callback(success, ...args);
            currentTimeoutIndex.current++;
            rs(response);
            retryingPromise.current = null;
          } catch (err) {
            rj(err);
          }
        }, calculateTimeout(currentTimeoutIndex.current));
      });

      retryingPromise.current.then((res) => resolve(res));
      retryingPromise.current.catch((err) => reject(err));
    });
  };
}

export const TemPayProvider = ({ children }: TemPayProviderTypes): JSX.Element => {
  const api = useApi();

  const { user, onixCode } = useAuth();

  const [card, setCard] = useState<CardType['data']>(null);
  const [statement, setStatement] = useState<CardStatementType[] | null>(null);
  const [loadingCard, setLoadingCard] = useState<boolean>(false);
  const [loadingStatement, setLoadingStatement] = useState<boolean>(false);

  const userDocumentNumber = user?.cpf.replace(/[^\d]/g, '');
  const cardNumber = card?.numero_cartao;

  const loadCard = useProgressiveRetry(async (success: GenericFunction) => {
    setLoadingCard(true);
    try {
      const { data } = await api.cardList(user?.cpf);
      setCard(data);
      success();
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setLoadingCard(false);
    }
  });

  const getCardStatement = useProgressiveRetry(
    async (success: GenericFunction, document: string, card: string) => {
      setLoadingStatement(true);
      try {
        const { data } = await api.cardStatement(document, card);

        data.sort((a, b) => {
          return new Date(b.data_transacao).getTime() - new Date(a.data_transacao).getTime();
        });

        setStatement(data);
        success();
      } catch (err) {
        console.error('error cardStatement', err?.response);
      } finally {
        setLoadingStatement(false);
      }
    },
  );

  useEffect(() => {
    if (user && onixCode && !card && !loadingCard) {
      void loadCard();
    }
  }, [user, card, loadingCard, onixCode]);

  useEffect(() => {
    if (user && card && onixCode && !loadingStatement && !statement) {
      try {
        void getCardStatement(userDocumentNumber!, cardNumber!);
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, card, loadingStatement, statement, onixCode]);

  return (
    <TemPayContext.Provider
      value={{
        card,
        statement,
        loadingStatement,
        loadingCard,
        loadCard,
      }}>
      {children}
    </TemPayContext.Provider>
  );
};
