import { IFeature } from '~/@types/auth/feature';

export type ValidateUserFeaturesParams = {
  features?: string | string[];
  userFeatures: IFeature[];
  config: 'some' | 'every';
};

export const validateUserFeatures = ({
  features,
  userFeatures,
  config = 'some',
}: ValidateUserFeaturesParams) => {
  const partnerFeatures = userFeatures.map(({ name, active }) => (active ? name : undefined));
  if (Array.isArray(features) && features.length > 0) {
    const hasValidfeatures = features[config]((feature) => {
      return partnerFeatures.includes(feature);
    });

    if (hasValidfeatures) {
      return true;
    }
  }

  if (typeof features === 'string') {
    const hasValidfeature = partnerFeatures.includes(features);

    if (hasValidfeature) {
      return true;
    }
  }

  return false;
};
