import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export function FamilyIcon({ width, height }: IconProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 16a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zm6 13a6 6 0 10-12 0 1 1 0 102 0 4 4 0 118 0 1 1 0 002 0zm0-15a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zm6 13a6.006 6.006 0 00-6-6 1 1 0 100 2 4 4 0 014 4 1 1 0 002 0zM6 8a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4zM2 15a4 4 0 014-4 1 1 0 000-2 6.006 6.006 0 00-6 6 1 1 0 102 0z"
        fill="#FFFFFC"
      />
    </Svg>
  );
}
