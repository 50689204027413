import React from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { CardWrapper } from '../TemPayDashboard/styles';
import {
  CardButton,
  PageWithCard,
  PaymentCard,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import { useTemPay } from '~/hooks/temPay';
import { useAuth } from '~/auth/legacy/useAuth';
import { currency } from '~/utils/strings';
import CardIcon from '~/components/Icons/CardIcon';
import FileIcon from '~/components/Icons/FileIcon';
import LockPasswordIcon from '~/components/Icons/LockPasswordIcon';
import QuestionMarkIcon from '~/components/Icons/QuestionMarkIcon';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { isNumeric } from '~/utils/isNumeric';

export const TemPayDashboard = (): JSX.Element => {
  const navigation = useNavigation();
  const { card, loadingCard, loadCard } = useTemPay();
  const { user, checkIsEnabledFeature } = useAuth();
  const theme = useTheme();
  const { isDeepLink } = DeepLink.useParams();

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const handleGoBack = () => {
    if (isDeepLink) {
      DeepLink.sendEvent(DeepLinkEvent.Cancel);
    } else {
      navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Dashboard');
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      void loadCard().catch(() =>
        showSweetAlert(
          'Erro ao verificar saldo',
          'Não foi possível verificar o saldo da sua conta neste momento. Por favor, tente novamente mais tarde.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                variant: 'primary',
                text: 'Ok',
                onPress: () => {
                  hideSweetAlert();
                },
              },
            ],
          },
        ),
      );
    }, []),
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Conta Saúde',
        onBack: isDeepLink ? undefined : handleGoBack,
      }}>
      <PaymentCard
        loading={loadingCard}
        name={user?.name}
        cardNumber={card?.numero_cartao.replace(/(\d{4})/g, '$1 ')}
        cardVia={card?.via}
      />
      {!checkIsEnabledFeature('hide_recharge') && (
        <View className="items-center justify-center self-center">
          <View className="mb-12 mobile:mb-8">
            <Typography variant="helperText">Saldo disponível na Conta Saúde</Typography>
          </View>
          <View className="flex-row items-end">
            <View className="mb-8 mr-4">
              <Typography
                variant="title"
                color="paragraph"
                style={{ lineHeight: 18, fontSize: theme.isMobile ? 12 : 14 }}>
                R$
              </Typography>
            </View>
            <Typography variant="h2" color="paragraph">
              {isNumeric(card?.saldo_d) ? currency(card?.saldo_d ?? 0) : '--'}
            </Typography>
          </View>
        </View>
      )}

      <View className="mt-40 mobile:mt-24">
        {!checkIsEnabledFeature('hide_recharge') && (
          <CardWrapper isFirst>
            <CardButton
              title="Recarga"
              description="Adicione saldo na Conta Saúde"
              iconSideOfTitle
              variant="large"
              icon={<CardIcon color={theme.colors.primary} />}
              onPress={() => navigation.navigate('TemPayRecharge')}
            />
          </CardWrapper>
        )}
        {!checkIsEnabledFeature('hide_recharge') && (
          <CardWrapper>
            <CardButton
              title="Extrato"
              description="Histórico de transações na Conta Saúde"
              iconSideOfTitle
              variant="large"
              icon={<FileIcon color={theme.colors.primary} />}
              onPress={() => navigation.navigate('TemPayStatement')}
            />
          </CardWrapper>
        )}

        <CardWrapper isFirst={checkIsEnabledFeature('hide_recharge')}>
          <CardButton
            title="Senha da Conta Saúde"
            description="Atualize sua senha para pagamentos"
            iconSideOfTitle
            variant="large"
            icon={<LockPasswordIcon color={theme.colors.primary} />}
            onPress={() => navigation.navigate('TemPayPassword')}
          />
        </CardWrapper>
        <CardWrapper>
          <CardButton
            title="Principais Dúvidas"
            description="Saiba mais sobre sua Conta Saúde"
            iconSideOfTitle
            variant="large"
            icon={<QuestionMarkIcon color={theme.colors.primary} />}
            onPress={() => navigation.navigate('TemPayHelp')}
          />
        </CardWrapper>
      </View>
    </PageWithCard>
  );
};
