import { IUser } from "~/@types/auth/user";
import { Appointment } from "~/screens/MyAppointments/state/types";

export function isAppointmentForHolder(appointment: Appointment, user: IUser) {
  const loggedUserCpf = user?.cpf?.replaceAll(/[.\-]/g, "");
  const appointmentCpf = appointment.cpf || appointment.cpf_titular;
  if (!appointmentCpf) {
    return user.name === appointment.patient?.name;
  }
  return appointmentCpf === loggedUserCpf;
}
