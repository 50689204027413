import React, { useCallback, useEffect, useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { goBack } from '~/helpers';
import { HealthProfessional, IHealthProfessionalItem } from '~/components/HealthProfessional';

export const SeniorAssistanceSchedule = ({ route }: any): JSX.Element => {
  const navigation = useNavigation();

  const healthProfessionals: IHealthProfessionalItem[] = [
    {
      label: 'Psicologia',
      professionalType: 'PSYCHOLOGIST',
    },
    {
      label: 'Enfermeiro Geriatra',
      professionalType: 'NURSE',
    },
    {
      label: 'Nutrição',
      professionalType: 'NUTRITIONIST',
    },
  ];

  return (
    <PageWithCard
      variant={PageWithCardVariant.Simple}
      simpleOptions={{
        title: 'Consultas e Terapia',
        onBack: () => {
          goBack(navigation, 'BenefitsSeniorAssistance');
        },
      }}>
      <View>
        <Typography variant="helperText">
          Atendimento profissional para cuidar da sua saúde física e emocional.
        </Typography>
      </View>
      <View className="pt-24">
        <HealthProfessional
          screenName="BenefitsVideoAppointmentsSchedule"
          benefit="senior_assistance"
          title="Consultas por Vídeo Agendada"
          subtitle="Agende sua Consulta por Vídeo sozinho as especialidades:"
          items={healthProfessionals}
        />
      </View>

      <View className="pt-24">
        <HealthProfessional
          items={[
            {
              label: 'Fisioterapia',
              professionalType: 'PHYSIOTHERAPIST',
            },
          ]}
          screenName="SeniorScheduleAppointment"
          title="Terapia Presencial"
          subtitle="Solicite um agendamento para realizar sua terapia presencialmente"
          benefit="senior-assistance"
        />
      </View>

      <MobileAutoSpace />
    </PageWithCard>
  );
};
