import { Await, Planned, Results, SendMedicalRequest } from './steps';

export const CHECKUP_STEPS = [
  {
    title: 'Consulta',
    step: 'appointment',
    steps: [Await, Planned],
  },
  {
    title: 'Exames',
    step: 'exam',
    steps: [SendMedicalRequest, Await, Planned],
  },
  {
    title: 'Retorno',
    step: 'recurrence',
    steps: [Results, Await, Planned],
  },
];
