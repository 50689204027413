import React, { useEffect } from 'react';
import { ProgressBar } from 'react-native-paper';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { useSweetAlert } from '../hooks';

import { iconType, SweetAlertButton } from '../types';
import { ErrorIcon, InformationIcon, SuccessIcon, WarningIcon } from '../icons';
import { LocalizationIcon } from '../icons/Localization';
import { IconAlert } from './style';
import { Button, CustomButtonProps, Typography, useMedia } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';

export const HelloUiLayout = (): JSX.Element => {
  const media = useMedia();
  const theme = useTheme();

  const { user } = useAuth();

  const { alertType, statusAlert, alertTitle, alertMessage, hideSweetAlert, autoClose, custom } =
    useSweetAlert();

  useEffect(() => {
    if (statusAlert && autoClose) {
      setTimeout(() => hideSweetAlert(), 3000);
    }
  }, [statusAlert]);

  const iconByAlertType: iconType = {
    success: <SuccessIcon />,
    error: <ErrorIcon />,
    warning: <WarningIcon />,
    info: <InformationIcon />,
    localization: <LocalizationIcon color={!user ? theme.colors.success : theme.colors.primary} />,
  };

  const progressColors = {
    success: theme.colors.success,
    error: theme.colors.error,
    warning: theme.colors.warning,
    info: theme.colors.info,
    primary: theme.colors.primary,
    localization: !user ? theme.colors.success : theme.colors.primary,
  };

  return (
    <View className="m-auto w-full items-center justify-center text-center">
      <View
        className={`mb-[16px] mt-0 h-[260px] items-center justify-center pt-0 text-center mobile:mt-32 ${
          theme.platform?.OS !== 'web' && theme.isMobile ? 'mobile:h-[210px]' : ''
        }`}>
        <IconAlert>{iconByAlertType[alertType]}</IconAlert>

        <View className="mb-42 mt-16 h-[180px] mobile:mb-24 mobile:mt-20 mobile:h-[130px]">
          <Typography variant="title" style={{ textAlign: 'center' }} testID="alert-title">
            {alertTitle}
          </Typography>
          {alertMessage ? (
            <View
              className="mt-32 min-h-[300px] max-w-[400px] pb-32 mobile:mb-24 mobile:mt-12"
              testID="alert-message">
              <Typography
                variant={theme.isMobile ? 'body2' : 'body1'}
                style={{ textAlign: 'center' }}>
                {alertMessage}
              </Typography>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
      <View
        className={'h-60 w-full max-w-[400px] flex-row items-center justify-center text-center'}>
        {custom?.buttons &&
          custom?.buttons.map((button: SweetAlertButton, index) => (
            <View
              className={`${custom?.buttons?.length > 1 ? 'flex-auto' : 'w-full '} ${
                index === 0 ? 'mr-4' : 'ml-4'
              }`}
              key={index}>
              <Button
                variant={button.variant as CustomButtonProps['variant']}
                onPress={button.onPress}
                style={button.style}>
                {button.text}
              </Button>
            </View>
          ))}
      </View>
      {autoClose && (
        <ProgressBar
          style={{
            height: 4,
            width: media.isMobile ? 280 : 300,
            marginTop: 20,
          }}
          indeterminate
          color={progressColors[custom?.color ?? 'primary']}
        />
      )}
    </View>
  );
};
