import React, { useCallback, useEffect } from 'react';
import { useNavigation } from '@react-navigation/core';
import { useRoute } from '@react-navigation/native';
import { Stepper } from '~/components/@hello-ui/Stepper/Stepper';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { useAppointment } from '~/hooks/appointment';
import { useUploader } from '~/components/@tem-ui/Uploader';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { useApi } from '~/hooks/api';
import * as S from './styles';
import { ScheduleReview } from '~/screens/ScheduleAppointment/components/ScheduleReview';
import { trackingEventType } from '~/contexts/tracking/types';
import { TypesEnum } from '~/enums/appointment';
import { ScheduleLocalType } from '~/screens/ScheduleAppointment/components/ScheduleLocalType';
import { ScheduleLocal } from '~/screens/ScheduleAppointment/components/ScheduleLocal';
import { SchedulePreference } from '~/screens/ScheduleAppointment/components/SchedulePreference';
import { ScheduleDate } from '~/screens/ScheduleAppointment/components/ScheduleDate';
import { SelectDependents } from '~/screens/ScheduleAppointment/components/SelectDependents/SelectDependents';
import { ScheduleSuccess } from '~/screens/ScheduleSuccess';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import { useAuth } from '~/auth/legacy/useAuth';

const types = {
  'consulta-presencial': 'Solicitação de consulta',
};

export const SeniorScheduleAppointment = ({}: {
  needToClear?: boolean;
} = {}): JSX.Element => {
  const navigation = useNavigation();
  const {
    scrollDisabled,
    customPadding,
    currentStep,
    stepper,
    finalizeSteps,
    stepData,
    setCurrentStepState,
    toStepper,
    overrideBodyMaxWidth,
  } = useStepper();

  const { params } = useRoute<UseRoute<'ScheduleAppointment'>>();
  const { checkIsEnabledFeature } = useAuth();
  const { isDeepLink, feature } = DeepLink.useParams();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearUploader } = useUploader();
  const api = useApi();
  const { appointment, setAppointment, clearAppointments } = useAppointment();

  useEffect(() => {
    clearUploader();
    toStepper(0);
  }, []);

  const title = stepData?.customTitle ? stepData?.customTitle : stepData?.title;

  useEffect(() => {
    if (params?.toStepper !== undefined) {
      toStepper(params.toStepper);
    }
  }, [params]);

  const handleGoBack = () => {
    if (currentStep >= 0 && stepper !== null) {
      appointment?.notFoundAttatchment ? clearAppointments() : showCancelAlert();
    } else {
      if (isDeepLink) {
        DeepLink.sendEvent(DeepLinkEvent.Cancel);
      } else {
        finalizeSteps();
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Dashboard');
      }
    }
  };

  const getData = useCallback(async () => {
    const { data } = await api.specialities({ type: 'network' });

    setAppointment({
      ...appointment,
      specialty: data.find((item) => item.name === 'Fisioterapia'),
    });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ appointment:', appointment);
  }, [appointment]);

  const showCancelAlert = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja sair da solicitação de agendamento?',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              clearUploader();
              finalizeSteps();
              setCurrentStepState(0);
              clearAppointments();

              if (params?.toStepper !== undefined && isDeepLink) {
                DeepLink.sendEvent(DeepLinkEvent.Cancel);
                toStepper(params.toStepper);
              }

              showSweetAlert(
                'Tudo certo!',
                'Sua solicitação foi cancelada',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  return (
    <PageWithCard
      simpleOptions={{
        onBack:
          currentStep === 0 && isDeepLink && !appointment?.notFoundAttatchment
            ? undefined
            : handleGoBack,
        title: 'Solicitação de consulta',
      }}
      overrideBodyMaxWidth={overrideBodyMaxWidth}
      overrideScrollEnabled={!scrollDisabled}
      overridePadding={customPadding}>
      <S.StepperContainer>
        <Stepper
          title="O que você precisa?"
          description="Selecione o serviço que deseja solicitar o agendamento:"
          items={[
            {
              icon: <StethoscopeIcon />,
              title: 'Consulta Presencial',
              description: 'Especialidades médicas e terapias',
              customTitle: 'Solicitação de consulta',
              description: 'Especialidades médicas e terapias',
              disabled: !checkIsEnabledFeature('health_network'),
              visible: checkIsEnabledFeature('health_network'),
              visibilitySteps: false,
              steps: [
                <ScheduleLocal key="ScheduleLocal" />,
                <SchedulePreference
                  key="SchedulePreference"
                  setFlowDate={() => setFlowDate(true)}
                />,
                <ScheduleDate key="ScheduleDate" />,
                <SelectDependents key="SelectDependent" />,
                <ScheduleReview
                  key="ScheduleReview"
                  scheduleType={TypesEnum.Appointment}
                  trackingType={trackingEventType.RequestedInPresentialAppointment}
                />,
                <ScheduleSuccess key="presentionSuccess" />,
              ],
              tracking: trackingEventType.ClickedOnPresentialAppointment,
            },
          ]}
          validateDependentFeatures
        />
      </S.StepperContainer>
    </PageWithCard>
  );
};
