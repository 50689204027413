import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../../assets/images/SeniorAssistanceResidentialInspection/desktop.png';
import mobileBackground from '../../../assets/images/SeniorAssistanceResidentialInspection/mobile.png';
import { SeniorAssistanceContactMethods } from '../components/ContactMethods';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { ResidentialInspectionIcon } from '~/components/Icons/ResidentialInspection';
import { goBack } from '~/helpers';

export const SeniorAssistanceResidentialInspection = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        backgroundImage,
        titleStartContent: <ResidentialInspectionIcon color={theme.colors.white} />,
        title: 'Vistoria Residencial',
        onBack: () => {
          goBack(navigation, 'BenefitsSeniorAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Garanta <Typography variant="bodyHighlight2">mais segurança </Typography>e{' '}
          <Typography variant="bodyHighlight2">conforto</Typography> em casa com uma vistoria que
          identifica e sugere melhorias essenciais para o dia a dia. O serviço é pensado para tornar
          o ambiente mais seguro e funcional.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            Um profissional especializado realiza uma{' '}
            <Typography variant="bodyHighlight2">avaliação completa do imóvel</Typography>,
            identificando riscos como móveis mal posicionados, falta de apoio em áreas importantes,
            pisos escorregadios e outros pontos que possam comprometer a mobilidade e a segurança.
          </Typography>
          <View className="mb-8 mt-8">
            <Typography variant="body2">
              Ao final, você recebe um{' '}
              <Typography variant="bodyHighlight2">laudo detalhado</Typography> com todas as
              <Typography variant="bodyHighlight2">sugestões</Typography> de{' '}
              <Typography variant="bodyHighlight2">adequações necessárias</Typography>, com
              orientações práticas para adaptar o lar às suas necessidades.
            </Typography>
          </View>
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a 3
            acionamentos ao ano.
          </Typography>
        </Collapse>
        <Collapse title="Como utilizar">
          <Typography variant="body2">
            Agende uma visita no dia e horário que preferir, receba o profissional para uma análise
            completa da sua casa. Ao fim consulte o laudo entregue com todas as recomendações para
            tornar o seu lar mais seguro e funcional.
          </Typography>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <SeniorAssistanceContactMethods />
    </PageWithCard>
  );
};
