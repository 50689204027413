import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { IconProps } from './types';

const HospitalAdmisionIcon = ({ color, width, height }: IconProps): JSX.Element => {
  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.249 8.157a1.006 1.006 0 00-1.218.72 1 1 0 00.72 1.218A2.997 2.997 0 0122 13v2H10v-2c0-1.654 1.346-3 3-3a.999.999 0 100-2 5.005 5.005 0 00-4.428 2.681 3.478 3.478 0 00-2.072-.68 3.504 3.504 0 00-3.162 5H2V5a1 1 0 10-2 0v18a1 1 0 102 0v-2h20v2a1 1 0 102 0V13a5 5 0 00-3.751-4.844zM5 13.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5S7.327 15 6.5 15 5 14.327 5 13.5zM2 19v-2h20v2H2zM7 4a1 1 0 011-1h3.965L13.668.445a1.003 1.003 0 011.755.17l1.865 4.477 1.443-1.732a1 1 0 01.769-.359H23a1 1 0 110 2h-3.031l-2.2 2.641a1.003 1.003 0 01-.918.348 1 1 0 01-.773-.604l-1.781-4.275-.964 1.445a1.001 1.001 0 01-.832.445h-4.5a1 1 0 01-1-1H7z"
        fill={color ?? '#EC6338'}
      />
    </Svg>
  );
};

export default HospitalAdmisionIcon;
