import React from 'react';
import { View } from 'react-native';
import { DetailsListProps } from '~/components/@hello-ui/DetailsList/types';
import { Divider, Typography } from '~/components/@hello-ui';

export const DetailsList: React.FC<DetailsListProps> = ({ items }) => {
  return (
    <View className="rounded-[8px] border-[1px] border-gray-disabled">
      {items.map((item, index) => (
        <>
          <View className="px-24 py-16 mobile:px-16 mobile:py-12">
            <Typography
              variant="body2"
              color="paragraph"
              className="text-[18px] mobile:text-[16px]">
              {item.title}
            </Typography>
            {item.description && (
              <Typography variant="body2" color="title" className="text-[16px] mobile:text-[14px]">
                {item.description}
              </Typography>
            )}
          </View>
          {index !== items.length - 1 && <Divider />}
        </>
      ))}
    </View>
  );
};
