import React from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { differenceInCalendarMonths } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, PageWithCard, Typography, useSweetAlert } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { trackingEventType } from '~/contexts/tracking/types';
import { useApi } from '~/hooks/api';
import { useTracking } from '~/services/tracking';
import { AuthService } from '~/auth/auth-service';

import { NavigationProps } from '~/@types/navigation/routes-helpers';
import { toCamelCase } from '~/utils/strings';
import { AlertIconOutlined } from '~/components/Icons/AlertIconOutline';
import { instanceApi } from '~/App';

interface DependentsReasonProps extends NavigationProps<'DependentsReason'> {}

const schema = yup.object().shape({
  reason: yup.string().required('Descreva um motivo'),
});

export const DependentsReason: React.FC<DependentsReasonProps> = ({ route }) => {
  const navigation = useNavigation();
  const { params } = route;
  const { dependents } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const api = useApi();
  const { tracking } = useTracking();

  const dependent = (dependents ?? []).find((d) => {
    return d.cpf === params?.cpf;
  });

  const {
    control,
    formState: { isValid },
    getValues,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      reason: '',
    },
  });

  const showConfirmDelete = () => {
    showSweetAlert(
      'Remover dependente?',
      'Ao clicar SIM você removerá o dependente.',
      'warning',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => hideSweetAlert(),
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: deleteDependent,
          },
        ],
      },
    );
  };

  const deleteDependent = async () => {
    try {
      if (
        dependent.onix === '7124' &&
        differenceInCalendarMonths(new Date(), new Date(dependent.associationStartDate)) < 12
      ) {
        showSweetAlert(
          '',
          'O(a) dependente não pode ser excluído(a) pois não se cumpriu o prazo mínimo de permanência no programa',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                text: 'Ok',
                variant: 'primary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      } else {
        showSweetAlert('Deletando...', '', 'info', false, false, {
          layout: 'helloUi',
        });
        const formValues = getValues();

        await instanceApi.reasonExclusion({
          reason: formValues.reason,
          onix: dependent.onix,
          documentDependente: dependent.cpf,
        });

        await api.deleteDependent(dependent.cpf);
        await AuthService.updateDependents();

        void tracking.addMovement({
          event: trackingEventType.DependentDeleted,
        });

        showSweetAlert(
          'Dependente removido',
          `O dependente ${toCamelCase(dependent?.name)} foi removido com sucesso.`,
          'success',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                text: 'Ok',
                variant: 'primary',
                onPress: () => {
                  hideSweetAlert();
                  navigation.navigate('Dependents');
                },
              },
            ],
          },
        );
      }
    } catch (e) {
      console.error(e);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível excluir o dependente. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  };

  const onSubmit = (data: any) => {
    showConfirmDelete();
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Dependentes',
        onBack: () => navigation.navigate('Dependents'),
      }}>
      <Typography variant="title" className="mb-24 text-[18px] mobile:mb-16 mobile:text-[16px]">
        Motivo da remoção
      </Typography>
      <Typography variant="body2" className="mb-24 mobile:mb-16">
        Para remover o dependente{' '}
        <Typography variant="bodyHighlight1" className="mb-24 mobile:mb-16">
          {toCamelCase(dependent?.name)}
        </Typography>{' '}
        informe abaixo o motivo.
      </Typography>

      <View className="mb-25 rounded-lg bg-[#FEEED1] p-20">
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <AlertIconOutlined width={30} height={30} />
          <Typography variant="helperText" color={'#F09F1C'} style={{ marginTop: -6 }}>
            Acesso do dependente
          </Typography>
        </View>
        <Typography className="mt-3" variant="helperText">
          O dependente{' '}
          <Typography variant="titleXsmallBold">perderá todos os benefícios </Typography> do seu
          programa de saúde, e os dependentes maiores de 18 anos deixarão de ter acesso à plataforma
          assim que a remoção for concluída.
        </Typography>
      </View>

      <View className="mb-8 mt-5">
        <Controller
          name="reason"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={(event) => {
                onChange(
                  event === ' '
                    ? event.replace(/( ){1,}/g, '')
                    : event.replace(/[0-9]/g, '').replace(/( ){2,}/g, ''),
                );
              }}
              placeholder="Descreva o motivo..."
              label="Motivo"
              error={error?.message}
              isRequired
            />
          )}
        />
      </View>

      <Button variant="primary" onPress={handleSubmit(onSubmit)}>
        Finalizar
      </Button>
    </PageWithCard>
  );
};
