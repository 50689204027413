import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';

import { IOlimpoRes } from '~/services/api/olimpo/interfaces/olimpo-res';
import { IUser } from '~/@types/auth/user';
import {
  IAxiosCheckFirstAccessRes,
  ICheckFirstAccessRes,
  ILoginRes,
  IPasswordFirstAccessPayload,
  IRefreshTokenPayload,
  IRefreshTokenRes,
} from '~/services/api/olimpo/interfaces/login-domain';

export class LoginDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async login(cpf: string, password: string): Promise<ILoginRes> {
    const res = await this.olimpoService.axios.post<IOlimpoRes<IUser>>('/v1/hello/login', {
      cpf,
      password,
    });
    const headers = res.headers as { tokenzeus: string; refreshtoken: string };

    return { user: res.data.data, token: headers.tokenzeus, refreshToken: headers.refreshtoken };
  }

  async refreshToken({ token, refreshToken }: IRefreshTokenPayload): Promise<IRefreshTokenRes> {
    const res = await this.olimpoService.axios.put(
      '/v1/hello/refresh-token',
      { tokenzeus: token, refreshToken },
      {
        skipRefreshToken: true,
      },
    );
    const headers = res.headers as { tokenzeus: string; refreshtoken: string; expire_in: string };

    return {
      token: headers.tokenzeus,
      refreshToken: headers.refreshtoken,
      expireIn: headers.expire_in,
    };
  }

  async checkFirstAccess(cpf: string): Promise<ICheckFirstAccessRes> {
    const res = await this.olimpoService.axios.get<IOlimpoRes<IAxiosCheckFirstAccessRes>>(
      `/v1/hello/check-first-access/${cpf}`,
    );
    return {
      clientType: res.data.data.clientType,
      elegible: res.data.data.elegible,
      firstAccess: res.data.data.first_access,
      hasTelephone: res.data.data.has_telephone,
      hasBirthdate: res.data.data.has_birthdate,
      isOverLegalAge: res.data.data.isOverLegalAge,
      telephone: res.data.data.telephone,
      isMercadoPago: res.data.data.isMercadoPago,
      products: res.data.data.products ?? [],
    };
  }

  checkHasPassword(cpf: string) {
    return this.olimpoService.axios.get(`/v1/hello/check-has-password/${cpf}`);
  }

  async passwordFirstAccess(payload: IPasswordFirstAccessPayload) {
    await this.olimpoService.axios.post('/v1/hello/new-password', {
      documentNumber: payload.cpf,
      password: payload.password,
      code: payload.code,
      validationToken: payload.validationToken,
    });
  }
}
