import React, { type ReactElement } from 'react';
import { View } from 'react-native';

type ProgressRootProps = {
  children: ReactElement | ReactElement[];
};

export const ProgressRoot = ({ children }: ProgressRootProps) => {
  return <View className={'w-full flex-row justify-center'}>{children}</View>;
};
