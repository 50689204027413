import React from 'react';

import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import desktopBackground from '../../assets/images/BenefitsHospitalAdmision/desktop.png';
import mobileBackground from '../../assets/images/BenefitsHospitalAdmision/mobile.png';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography, useMedia } from '~/components/@hello-ui';
import { goBack } from '~/helpers';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import HospitalAdmisionIcon from '~/components/Icons/HospitalAdmisionIcon';

export const BenefitsHospitalAdmision = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <HospitalAdmisionIcon width={26} height={26} color="white" />,
        title: 'Diária por Internação Hospitalar',
        onBack: () => {
          goBack(navigation, 'Dashboard');
        },
      }}>
      <View>
        <Typography variant="body2">
          A Diária por Internação Hospitalar oferece suporte financeiro para momentos delicados.
          Desde que a causa da internação seja coberta pelo seguro, você receberá uma indenização
          para cada dia no hospital, conforme o limite da sua cobertura, ajudando a enfrentar esse
          período com mais tranquilidade.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-8">
            <Typography variant="body2">
              O período de franquia é de até 15 dias consecutivos, contados a partir da data do
              evento (data do afastamento por orientação médica). Após o término desse período, as
              indenizações são iniciadas no dia seguinte e continuam até o final do afastamento,
              respeitando o limite de diárias contratado.
            </Typography>
          </View>
          <View className="mb-8 space-y-4">
            <Typography variant="body2">
              Em caso de acidentes pessoais, não há carência, exceto para situações de suicídio ou
              tentativa, que possuem uma carência de 2 anos. Para mais detalhes sobre o benefício,
              consulte o regulamento do seu produto.
            </Typography>
          </View>
          <View className="flex-row pt-4">
            <Typography
              variant="link"
              className="pr-4 text-sm"
              onPress={() => navigation.navigate('RegulationAndPrivacy')}>
              Regulamento
            </Typography>
            <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
          </View>
        </Collapse>
        <Collapse title="Como utilizar?">
          <View className="mb-4">
            <Typography variant="body2">
              Para solicitar o benefício, basta ligar para o número abaixo, informar seu CPF e
              seguir as orientações do nosso atendente.
            </Typography>
          </View>
        </Collapse>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício ligue:</Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08007782052')}>
          0800 778 2052
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon width={30} height={30} color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="body1" color={theme.colors.paragraph}>
            <Typography variant="subtitle" color={theme.colors.paragraph}>
              0800 778 2052
            </Typography>
          </Typography>
        </View>
      )}
    </PageWithCard>
  );
};
