import React, { useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';

import { useAuth } from '~/auth/legacy/useAuth';
import { KeyIcon } from '~/components/Icons/Key';
import { PlumberIcon } from '~/components/Icons/Plumber';
import { ElectricianIcon } from '~/components/Icons/Electrician';
import CircleGroup from '~/components/Icons/CircleGroup';
import PhoneIcon from '~/components/Icons/PhoneIcon';
import VideoCall24Icon from '~/components/Icons/VideoCall24Icon';
import { Dentistry24HoursIcon } from '~/components/Icons/Dentistry24hours';

export const Benefits24Hours = (): JSX.Element => {
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          icon: <VideoCall24Icon width={24} height={24} />,
          title: 'Consulta por Vídeo 24 horas',
          description: 'Telemedicina com clínico geral',
          onPress: () =>
            navigation.navigate('Schedule24h', { fromPage: 'Benefits24Hours', toStepper: 0 }),
          visible: checkIsEnabledFeature('telemedicine_24'),
        },
        {
          icon: <PhoneIcon width={24} height={24} />,
          title: 'Orientação Médica',
          description: 'Para dúvidas e informações sobre saúde por telefone',
          onPress: () => navigation.navigate('BenefitsTeleOrientation'),
          visible: checkIsEnabledFeature('teleorientation'),
        },
        {
          icon: <Dentistry24HoursIcon width={24} height={24} color='primary'/>,
          title: 'Assistência de Urgência Odontológica',
          description: 'Para cuidados odontológicos emergenciais',
          onPress: () => navigation.navigate('BenefitsDentistry24Hours'),
          visible: checkIsEnabledFeature('dentistry24hrs'),
        },
        {
          icon: <CircleGroup width={24} height={24} />,
          title: 'Assistência Funeral',
          description: 'Apoio em momentos sensíveis',
          onPress: () => navigation.navigate('BenefitsFunerals'),
          visible: checkIsEnabledFeature(['funeral', 'funeral_memorial', 'funeral_igs']),
        },
        {
          icon: <KeyIcon />,
          title: 'Chaveiro',
          description: 'Para arrombamento, roubo, furto ou conserto',
          onPress: () => navigation.navigate('BenefitsKeychain'),
          visible: checkIsEnabledFeature('residential_assistant'),
        },
        {
          icon: <PlumberIcon />,
          title: 'Encanador',
          description: 'Para alagamentos e problemas hidráulicos',
          onPress: () => navigation.navigate('BenefitsResidentialAssistancePlumber'),
          visible: checkIsEnabledFeature('residential_assistant'),
        },
        {
          icon: <ElectricianIcon />,
          title: 'Eletricista',
          description: 'Para danos elétricos da residência cadastrada',
          onPress: () => navigation.navigate('BenefitsResidentialAssistanceElectrician'),
          visible: checkIsEnabledFeature('residential_assistant'),
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Serviços 24 horas',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
