import React from 'react';
import { DefaultTheme } from 'styled-components/native';
import { Typography } from '~/components/@hello-ui';

export const makeTextByStatus = (theme: DefaultTheme) => ({
  requested: {
    text: 'Aguardar opções',
    color: theme.colors.paragraph,
  },
  scheduling: {
    text: 'Aguardar agendamento',
    color: theme.colors.paragraph,
  },
  accepted: {
    text: 'Agendado',
    color: theme.colors.paragraph,
  },
  finished: {
    text: 'Concluído',
    color: theme.colors.paragraph,
  },
  canceled: {
    text: 'Atendimento cancelado',
    color: theme.colors.paragraph,
  },
  suggested: {
    text: 'Escolher local',
    color: theme.colors.paragraph,
  },
  bmgSchedule: {
    text: 'Atendimento agendado',
    color: theme.colors.paragraph,
  },
});

export const makeTextByStage = (theme: DefaultTheme) => ({
  appointment: {
    text: 'Consulta',
    color: theme.colors.paragraph,
  },
  exam: {
    text: 'Exame',
    color: theme.colors.paragraph,
  },
  recurrence: {
    text: 'Retorno',
    color: theme.colors.paragraph,
  },
});

export const makeTextByCheckupStatus = (theme: DefaultTheme) => ({
  scheduling: {
    text: 'Aguardando agendamento',
    color: theme.colors.paragraph,
    paragraph: (): JSX.Element => {
      return (
        <Typography variant="body2">
          Nossa equipe está buscando o <Typography variant="bodyHighlight2Bold">dia</Typography> e o{' '}
          <Typography variant="bodyHighlight2Bold">horário</Typography> mais próximos para seu
          agendamento. Em breve entraremos em contato por{' '}
          <Typography variant="bodyHighlight2Bold">e-mail</Typography> e{' '}
          <Typography variant="bodyHighlight2Bold">WhatsApp</Typography> com atualizações.
        </Typography>
      );
    },
  },
  scheduled: {
    text: 'Agendado',
    color: theme.colors.paragraph,
    paragraph: (): JSX.Element => {
      return (
        <>
          <Typography variant="body2" className="mb-8 text-sm">
            Compareça no dia e horário agendados com 30 minutos de antecedência.
          </Typography>
          <Typography variant="body2" className="mb-8 text-sm">
            <Typography variant="bodyHighlight2Bold">Não se esqueça!</Typography> Leve sua guia de
            autorização e a lista de exames inclusos no seu Check-Up Médico para informar ao
            profissional.
          </Typography>
          <Typography variant="body2" className="mb-8 text-sm">
            Após a consulta,{' '}
            <Typography variant="bodyHighlight2Bold">envie seu pedido médico</Typography> por{' '}
            <Typography variant="bodyHighlight2Bold">WhatsApp</Typography> para seguir à próxima
            etapa.
          </Typography>
        </>
      );
    },
  },
  medicalRequest: {
    text: 'Enviar Pedido Médico',
    color: theme.colors.paragraph,
    paragraph: (): JSX.Element => {
      return (
        <>
          <Typography variant="body2" className="mb-8 text-sm">
            Nos <Typography variant="bodyHighlight2Bold">envie seu pedido médico</Typography> por{' '}
            <Typography variant="bodyHighlight2Bold">WhatsApp</Typography> para seguir à próxima
            etapa.
          </Typography>
        </>
      );
    },
  },
  waitingResults: {
    text: 'Aguardando Resultados',
    color: theme.colors.paragraph,
    paragraph: (): JSX.Element => {
      return (
        <>
          <Typography variant="body2" className="mb-8 text-sm">
            Com resultado em mãos, entre em contato por{' '}
            <Typography variant="bodyHighlight2Bold">WhatsApp</Typography> para seguir à próxima
            etapa.
          </Typography>
        </>
      );
    },
  },
  closed: {
    text: 'Encerrado',
    color: theme.colors.paragraph,
    paragraph: (): void => {
      <></>;
    },
  },
  finished: {
    text: 'Concluído',
    color: theme.colors.paragraph,
  },
  canceled: {
    text: 'Atendimento cancelado',
    color: theme.colors.paragraph,
  },
});
