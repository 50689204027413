import React, { useEffect, useState, useCallback } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { TouchableOpacity, View } from 'react-native';
import { useApi } from '~/hooks/api';
import { serializeString } from '~/utils/strings';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { Specialty } from '~/contexts/types';
import { Input } from '~/components/@hello-ui/Input';
import { Typography } from '~/components/@hello-ui';
import { Listing } from '~/components/@hello-ui/Listing';

interface ScheduleSpecialtyProps {
  isTelemedicine?: boolean;
  isCheckup?: boolean;
  onListEmpty?: (isEmpty: boolean) => void;
}

export const ScheduleSpecialty = ({
  isTelemedicine,
  isCheckup,
  onListEmpty,
}: ScheduleSpecialtyProps): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: false });
  const api = useApi();
  const theme = useTheme();

  const { appointment, setAppointment } = useAppointment();
  const { stepper, nextStep } = useStepper();
  const { tracking } = useTracking();

  const [term, setTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [fullSpecialties, setFullSpecialtie] = useState<Specialty[]>([]);

  useEffect(() => {
    setAppointment({
      ...appointment,
      specialty: null,
    });
  }, []);

  const sendMovementRecordAppointmentStatus = useCallback((specialty: Specialty) => {
    void tracking.addMovement({
      event: trackingEventType.ClickedOnScheduleSpecialty,
      metadata: {
        appointment: {
          specialty,
        },
      },
    });
    
    void setAppointment({
      ...appointment,
      specialty,
    });
  }, []);

  const renderItem = useCallback(({ item }) => {
    return (
      <TouchableOpacity
        className="w-full flex-row py-24"
        onPress={() => {
          sendMovementRecordAppointmentStatus(item);
          nextStep();
        }}>
        <Typography variant="body2" color={theme.colors.black}>
          {item.name}
        </Typography>
        <View className="absolute right-0 top-24">
          <Icon name="chevron-right" size={24} color={theme?.colors?.primary} />
        </View>
      </TouchableOpacity>
    );
  }, []);

  const getType = (isCheckupType: boolean, isTelemedicine: boolean, stepper: number): string => {
    if (isCheckupType) return 'checkup';
    if (isTelemedicine) return 'telemedicine';
    if (stepper === 1) return 'exam';
    return 'medical';
  };

  const getData = useCallback(async () => {
    const type = getType(!!isCheckup, !!isTelemedicine, stepper!);
    try {
      const { data } = await api.specialities({ type });
      const filteredData = data.filter((speciality: any) => speciality.type === type);
      setSpecialties(filteredData);
      setFullSpecialtie(filteredData);
    } catch (error) {
      console.error('Erro ao obter especialidades:', error);
    } finally {
      setLoading(false);
    }
  }, [isCheckup, isTelemedicine, stepper]);

  const handleSearch = useCallback(
    (text: string) => {
      const query: string = serializeString(text);
      const filteredData = fullSpecialties.filter(({ name }: Specialty) =>
        serializeString(name).includes(query),
      );
      setSpecialties(filteredData);
      setTerm(text);
    },
    [fullSpecialties],
  );

  useEffect(() => {
    void getData();
  }, [getData]);

  return (
    <>
      <View className="w-full max-w-[576px]">
        <Typography variant="title">Qual especialidade?</Typography>
      </View>
      <View className="mt-24 mobile:mt-16">
        <Input
          variant="search"
          value={term}
          onChangeText={(value) => handleSearch(value)}
          label="Pesquisar"
          placeholder="Digite a especialidade"
        />
      </View>
      <Listing data={specialties} loading={loading} renderItem={renderItem} useSeparator={true} />
    </>
  );
};
