import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import DesktopImage from '~/assets/images/dependents/desktop.png';
import MobileImage from '~/assets/images/dependents/mobile.png';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  PageWithCard,
  Typography,
} from '~/components/@hello-ui';
import { FamilyIcon } from '~/components/Icons/FamilyIcon';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { UseRoute } from '~/@types/navigation/routes-helpers';

export const DependentsPresentation = (): JSX.Element => {
  const navigation = useNavigation();
  const { params } = useRoute<UseRoute<'DependentsPresentation'>>();
  const theme = useTheme();
  const { isDeepLink } = DeepLink.useParams();

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        title: 'Dependentes',
        backgroundImage: theme.isMobile ? MobileImage : DesktopImage,
        titleStartContent: <FamilyIcon width={24} height={24} />,
        onBack: () => {
          if (isDeepLink) {
            DeepLink.sendEvent(DeepLinkEvent.Cancel);
          } else {
            navigation.canGoBack()
              ? params?.fromDependents
                ? navigation.navigate('Dashboard')
                : navigation.goBack()
              : navigation.navigate('Dashboard');
          }
        },
      }}>
      <Typography variant="body2" className="mb-32 mobile:mb-24">
        Cadastre seus dependentes e garanta que eles também tenham{' '}
        <Typography variant="bodyHighlight2">
          acesso a serviços{' '}
          <Typography variant="body2" className="mb-32 mobile:mb-24">
            e
          </Typography>{' '}
          benefícios.
        </Typography>
      </Typography>
      <Collapse title="Saiba mais">
        <Typography variant="body2" className="mb-16">
          Para saber quais benefícios o dependente possui, consulte o Regulamento do seu produto.{' '}
        </Typography>
        <View className="mb-24 mobile:mb-16">
          {!isDeepLink && (
            <TouchableOpacity
              className="flex-row items-center"
              onPress={() => navigation.navigate('RegulationAndPrivacy')}>
              <Typography variant="link" className="mr-8 text-[14px]">
                Regulamento
              </Typography>
              <FeatherIcon
                name="chevron-right"
                size={24}
                color={theme?.colors?.primary}
                testID="chevron-icon"
              />
            </TouchableOpacity>
          )}
        </View>
        <Typography variant="bodyHighlight2" className="mb-16 mobile:mb-8">
          Tempo de disponibilidade dos benefícios:
        </Typography>
        <Typography variant="body2">
          Os dependentes terão seus benefícios disponíveis de 20 minutos a 24 horas após o cadastro,
          a depender do serviço.
        </Typography>
        <Typography variant="bodyHighlight2" className="mb-16 mt-16 mobile:mb-8">
          Acesso à plataforma:
        </Typography>
        <Typography variant="body2">
          Os dependentes maiores de 18 anos deverão acessar a plataforma de forma independente,
          utilizando um login próprio. Já os dependentes menores continuarão a ser gerenciados pelo
          titular.
        </Typography>
      </Collapse>
      <MobileAutoSpace heightMobile={24} heightDesktop={80} />
      <Button variant="primary" onPress={() => navigation.navigate('Dependents')}>
        <>
          Visualizar Dependentes
          <Icon name="chevron-right" size={22} color={theme?.colors.white} />
        </>
      </Button>
    </PageWithCard>
  );
};
