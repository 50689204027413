import React from 'react';
import { View } from 'react-native';
import { clsx } from 'clsx';
import { useTheme } from 'styled-components/native';
import { pt } from 'date-fns/locale';
import { format } from 'date-fns';
import { useAuth } from '~/auth/legacy/useAuth';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { makeInfoByType } from '~/screens/MyAppointments/helpers/make-info-by-type';
import { makeTextByStatus, makeTextByCheckupStatus } from '~/screens/MyAppointments/helpers/make-text-by-status';
import { capitalize, currency } from '~/utils/strings';
import { Footer } from '~/components/Footer';
import { MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';
import { TypesEnum } from '~/enums/appointment';
import { ProgressBar } from '~/screens/AppointmentDetails/components/ProgressBar';
import { CHECKUP_STEPS } from '~/screens/AppointmentDetails/components/Steps/checkup';
import { RequestDetails } from '~/screens/AppointmentDetails/components/RequestDetails';
import { useAuthStore } from '~/store/auth.store';
import { isAppointmentForHolder } from '~/utils/isAppointmentForHolder';

const stepData  = {
  "appointment": {
    "scheduling": 0,
    "scheduled": 1,
    "canceled": 2
  },
  "exam": {
    "medicalRequest": 3,
    "scheduling": 4,
    "scheduled": 5,
    "canceled": 6
  },
  "recurrence": {
    "waitingResults": 7,
    "scheduling": 8,
    "scheduled": 9,
    "canceled": 10
  }
}

const stages = { 
  "appointment": "Primeira Consulta",
  "exam": "Exames",
  "recurrence": "Consulta de Retorno",
}


export const HistoryAppointment: React.FC = () => {
  const theme = useTheme();
  const { myAppointment } = useMyAppointments();
  const { onixCode, isDependent } = useAuth();

  const isMercadoPago = MERCADO_PAGO_ONIX.includes(onixCode?.onix_code?.toString() || '');

  const { 
    clinic_address, 
    specialty, 
    status: actualStatus, 
    type, 
    address,
    date,
    value_cents, 
    specialty_online,
    scheduled_date, 
    online_process, 
    statusStage,
    history 
  } = myAppointment!;

  const hasCompleted = actualStatus === 'finished';

  const isHolder = isAppointmentForHolder(myAppointment!!, useAuthStore().user!!);

  const appointmentType = makeInfoByType(theme);
  const checkupStatus = makeTextByCheckupStatus(theme);
  const statusType = makeTextByStatus(theme);
  
  const getDate = () => {
    try {
      if (!date) {
        return '-';
      }

      const convertedDate = new Date(date);

      return capitalize(format(convertedDate, "EEEE, d 'de' MMMM 'às' HH:mm", { locale: pt }));
    } catch (e) {
      return '-';
    }
  };

  return (
    <>
      {type === 'checkup' ? (
        <ProgressBar
          data={CHECKUP_STEPS}
          currentStep={stepData[statusStage][actualStatus]}
          status={hasCompleted ? 'completed' : 'closed'}
        />
      ) : (
        <View className="flex-row">
          <View
            className={clsx(
              'mr-24 h-56 w-56 items-center justify-center rounded-full mobile:mr-16 mobile:h-48 mobile:w-48',
              hasCompleted ? 'bg-success' : 'bg-error',
            )}>
            {appointmentType[type]?.icon}
          </View>
          <View>
            <Typography variant="bodyHighlight2" color="title">
              {appointmentType[type]?.description}
            </Typography>
            <Typography variant="body2" color="title">
              {specialty?.name ?? appointmentType[type]?.resume ?? ''}
            </Typography>
          </View>
          <View className="rounded-full p-8">
            <Typography variant="bodyHighlight2" className="text-center">
              {statusType[type]?.text}
            </Typography>
          </View>
        </View>
      )}
      <View
        className={clsx({
          'mb-40 mt-16 mobile:mb-24': type !== 'checkup',
          'mb-24 mt-6': type === 'checkup',
        })}>
        {type === 'checkup' ? (
          <>
            <Typography variant="body2" className="mb-8 text-sm">
              Check-Up Médico
            </Typography>

            <Typography
              variant="bodyHighlight2"
              className="rounded-lg p-8 text-center text-sm"
              style={{ backgroundColor: theme.colors.gray15 }}>
              {hasCompleted ? 'Concluído' : 'Encerrado'}
            </Typography>
          </>
        ) : (
          <Typography variant="body2">
            Protocolo: <Typography variant="bodyHighlight2">{myAppointment?.protocol}</Typography>
          </Typography>
        )}
      </View>
      <Collapse title={hasCompleted ? 'Detalhes do atendimento' : 'Detalhes da solicitação'}>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Protocolo
          </Typography>
          <Typography variant="body2">{myAppointment?.protocol}</Typography>
        </View>
         <View className="mb-16">
            <Typography variant="body2" color="placeholder">
              Tipo
            </Typography>
            <Typography variant="body2">
              {appointmentType[online_process]
                  ? appointmentType[online_process].description
                  : appointmentType[type].description ?? '-'}
            </Typography>
          </View> 
          {myAppointment?.type === "checkup" && (
           <View className="mb-16">
              <Typography variant="body2" color="placeholder">
                Especialidade
              </Typography>
              <Typography variant="body2">
                {specialty?.name ?? specialty_online?.name ?? '-'}
              </Typography>
            </View>
          )}
        {myAppointment?.type !== "checkup" && (  
        <View className="mb-16">
            <Typography variant="body2" color="placeholder">
              Dia e horário
            </Typography>
            <Typography variant="body2">{getDate()}</Typography>
          </View>
        )}
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Para quem
          </Typography>
          <Typography variant="body2">{`${myAppointment?.patient?.name} - ${isHolder ? 'Titular' : 'Dependente'}`}</Typography>
        </View>
        {!online_process ||
          (myAppointment?.type !== TypesEnum.CheckUp && (
            <View className="mb-16">
              <Typography variant="body2" color="placeholder">
                Endereço
              </Typography>
              <Typography variant="body2">{clinic_address || '-'}</Typography>
            </View>
          ))}

        {myAppointment?.type !== "checkup" && (  
          <View className="mb-16">
            <Typography variant="body2" color="placeholder">
              Valor do atendimento
            </Typography>
            <Typography variant="body2">
              {`R$ ${value_cents ? currency(value_cents / 100) : '-'}`}
            </Typography>
          </View>
        )}
        {myAppointment?.type === "checkup" && (
          <>
          <View className="mb-24">
          <Typography variant="body2" color="placeholder">
            Meu local
          </Typography>
          <Typography variant="body2">{address?.neighborhood + ", " + address?.state + " - " + address?.city}</Typography>
        </View>
        <View className="mb-24">
          <Typography variant="body2" color="placeholder">
            Data da solicitação
          </Typography>
          <Typography variant="body2">{getDate()}</Typography>
        </View>
        </>
        )}

        <View className="mb-24">
          {type === 'checkup' && (
            history?.map(({ status, intergrallStatus, local_atendimento, info_agendamento, cancelReason, stage, descStatus }, index) => (
              actualStatus && stage && ( 
              <RequestDetails.Step title={stages[stage]} key={`step_${index}`}>
                    <RequestDetails.StepItem title="Status">{intergrallStatus === "finished" ? 'Concluído' : "Encerrado"}</RequestDetails.StepItem>
                    {intergrallStatus === 'closed' ? (
                      <RequestDetails.StepItem title="Motivo do encerramento">
                        {descStatus}
                      </RequestDetails.StepItem>
                    ) : (
                      <></>
                    )}
                    {local_atendimento?.estado_desc && intergrallStatus !== 'closed' ? (
                      <RequestDetails.StepItem title="Endereço">{`${local_atendimento?.endereco}, ${local_atendimento.numero}, ${local_atendimento.estado} - ${local_atendimento.cidade}`}</RequestDetails.StepItem>
                    ) : (
                      <></>
                    )}
                    {info_agendamento?.data_atendimento && intergrallStatus !== 'closed' ? (
                      <RequestDetails.StepItem title="Dia e Horario">
                        {new Intl.DateTimeFormat('pt-BR', {
                          weekday: 'long',
                          day: '2-digit',
                          month: 'long',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric'
                        }).format(new Date(info_agendamento?.data_atendimento + " " + info_agendamento?.hora_atendimento))}
                      </RequestDetails.StepItem>
                    ) : (
                      <></>
                    )}
                  </RequestDetails.Step>
            )))
          )}
        </View>
      </Collapse>
      <View className="mt-40 mobile:mt-24" />
      {hasCompleted ? (
        <>
          <Typography variant="bodyHighlight2">Continue se cuidando!</Typography>
          <Typography variant="body2">
            Conte com a gente se precisar de um novo atendimento, estaremos por aqui.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="bodyHighlight2">Não se esqueça de se cuidar!</Typography>
          <Typography variant="body2">
            Conte com a gente para remarcar o seu atendimento.
          </Typography>
        </>
      )}
      <MobileAutoSpace />
      {!theme.isMobile && !isMercadoPago && (
        <Typography variant="bodyHighlight2" className="mt-24 text-center">
          Dúvidas? Fale conosco:
        </Typography>
      )}
      {!isMercadoPago && (
        <View className="mt-16 mobile:mt-24">
          <Footer
            variant="simple-phone-footer"
            showButtonOnMobile
            textButtonOnMobile="Dúvidas? Fale conosco:"
          />
        </View>
      )}
    </>
  );
};
