import React, { type ReactElement, useMemo } from 'react';

import { useTheme } from 'styled-components/native';

import { clsx } from 'clsx';

import { Progress, type StepProps } from './Progress';

type Section = {
  title: string;
  steps: (({ step, currentStep, color }: Omit<StepProps, 'icon'>) => ReactElement)[];
};

type ProgressBarProps = {
  data: Section[];
  currentStep?: number;
  status?: 'in-progress' | 'closed' | 'completed';
};

export const ProgressBar = ({
  data,
  currentStep = 0,
  status = 'in-progress',
}: ProgressBarProps) => {
  const theme = useTheme();
  const processedSteps = useMemo(() => {
    let idCounter = 0;
    return data.map((section) => ({
      title: section.title,
      steps: section.steps.map((step) => ({ id: idCounter++, step })),
    }));
  }, [data]);

  return (
    <Progress.Root>
      {processedSteps.map((section, sectionIndex) => {
        const firstStep = section.steps[0].id;
        const lastStep = section.steps[section.steps.length - 1].id;

        const completed = status === 'completed';
        const inProgress = status === 'in-progress';
        const closed = status === 'closed';
        const currentSection = currentStep >= firstStep && currentStep <= lastStep;
        const stepExecuted = currentStep >= firstStep;
        
        return (
          <Progress.Section key={section.title} closed={closed}>
            <Progress.Title
              active={
                (!completed && currentSection) ||
                (completed && sectionIndex === processedSteps.length - 1)
              }
              color={
                inProgress && stepExecuted
                  ? theme.colors.primary
                  : closed && stepExecuted
                    ? theme.colors.error
                    : completed
                      ? theme.colors.success
                      : undefined
              }>
              {section.title}
            </Progress.Title>

            <Progress.Bar
              first={sectionIndex === 0}
              last={sectionIndex === processedSteps.length - 1}
              color={completed ? theme.colors.success : closed ? theme.colors.error : undefined}
              active={stepExecuted || completed}
            />

            {!closed ? (
              <Progress.Steps>
                {section.steps.map(({ step: Step, id }) => (
                  <Step
                    key={id}
                    color={completed ? theme.colors.success : undefined}
                    step={id}
                    currentStep={completed ? lastStep + 1 : currentStep}
                  />
                ))}
              </Progress.Steps>
            ) : (
              <></>
            )}
          </Progress.Section>
        );
      })}
    </Progress.Root>
  );
};
