import React, { createContext, useContext, useCallback, useState } from 'react';
import { Specialty } from '~/contexts/types';
import { useApi } from '~/hooks/api';

interface CheckupContextProps {
  specialties: Specialty[];
  getSpecialities: () => Promise<void>;
}

export const CheckupContext = createContext<CheckupContextProps | undefined>(undefined);

export const CheckupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const api = useApi();
  const [specialties, setSpecialties] = useState<Specialty[]>([]);

  const getSpecialities = useCallback(async () => {
    try {
      const { data } = await api.specialities({ type: 'checkup' });
      setSpecialties(data);
    } catch (error) {
      console.error('Erro ao obter especialidades:', error);
    }
  }, [api]);
  
  return (
    <CheckupContext.Provider value={{ specialties, getSpecialities }}>
      {children}
    </CheckupContext.Provider>
  );
};

export const useCheckupContext = (): CheckupContextProps => {
  const context = useContext(CheckupContext);
  if (!context) {
    throw new Error('useCheckupContext deve ser usado dentro de um CheckupProvider');
  }
  return context;
};
